import { PageElement, html, css } from 'Components';

class Page extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
      `
    ];
  }

  render() {
    return html`
      <section-header backroute="/public/tests" micon="developer_board">Tests UX</section-header>
      <h3>
        <div style="display:flex;align-items:center;">
          <div><b>&lt;modal-drawer&gt;</b></div>
          <div style="font-weight:normal;padding-left:40px;font-size:0.8em;">Refactor du composant shoelace <a href="https://shoelace.style/components/drawer" target="shoelace">sl-drawer</a>.</div>
        </div>
      </h3>
      <br/>
      <ul>
        <li>modal ou non modal : gestion mousewheel différente</li>
        <li>meilleur maîtrise de l'overlay (fondu progressif vers la suppression des couleurs via <i>backdrop-filter</i>)</li>
        <li>slots pour les boutons</li>
      </ul>
      <br/>

      <sl-button @click=${() => this.open('modal')}>Ouvrir le drawer (modal)</sl-button>
      <sl-button @click=${() => this.open('nomodal')}>Ouvrir le drawer (non modal)</sl-button>
      
      <modal-drawer id="modal" label="drawer title" modal>
        Hello world !
        <sl-button slot="bt1" close="true" variant="primary">slot bt1 - OK</sl-button>
        <sl-button slot="bt2" close="true" variant="text">slot bt2 - Cancel</sl-button>
      </modal-drawer>

      <modal-drawer id="nomodal" label="drawer title">
        Hello world !
        <sl-button slot="bt1" close="true" variant="primary">slot bt1 - OK</sl-button>
        <sl-button slot="bt2" close="true" variant="text">slot bt2 - Cancel</sl-button>
      </modal-drawer>
    `;
  }

  open(id) {
    this.shadowRoot.querySelector(`#${id}`).show();
  }

}

customElements.define('page-tests-modal-drawer', Page);