import { PageElement, html, css } from 'Components';


class SelectPermissionsPage extends PageElement {

  static get styles() {
    return [
      super.styles,
      css`
        .flex {
          display:flex;
          align-items:center;
          gap:20px;
        }

        select-permissions {
          width:800px;
        }

        b {
          font-size:2em;
          height:40px;
        }
      `
    ]
  }

  static get properties() {
    return {
      value: { type: String },
    };
  }

  constructor() {
    super();
    this.debug = false;
    this.value = '';
  }

  async updated(changedProperties) {
    if (changedProperties.has('value')) {
      //this._log.debug('updated: value:', this.value);
    }
  }

  onChange(ev) {
    this.value = ev.detail.value;
    this.requestUpdate();
  }

  render() {

    return html`
      <section-header backroute="/public/tests" micon="developer_board">Tests UX</section-header><br/><br/>
      <b>${this.value || 'vide (hérité)'}</b>
      <br/><br/>
      <select-permissions value="${this.value}" @sl-change=${this.onChange}></select-permissions>
    `;
  }
}

customElements.define('page-tests-select-permissions', SelectPermissionsPage);