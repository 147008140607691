import { PageElement, html, css } from 'Components';
import { Router } from 'Router';

class LogoutPage extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        .error_container {
          padding:20px;
        }
      `
    ];
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            title:'Goodbye',
            text:'You have been successfully disconnected.',
            back:'Back to home',
          }
        },
        french:{
          translation: {
            title:'A bientôt',
            text:'Vous avez été déconnecté avec succès.',
            back:'Retourner à l\'accueil',
          }
        }
      }
    ]
  }

  render() {
    return html`
      <div class="content_page">
        <div class="item">
          <h3>${this._tl('title')}</h3>
          <hr-custom></hr-custom>
          <div class="error_container">
            <p>${this._tl('text')}</p>
            <br/>
            <sl-button @click="${this.goBack}" size="medium">${this._tl('back')}</sl-button>
          </div>
        </div>
      </div>
    `;
  }

  goBack() {
    Router.go('/');
  }

}

customElements.define('page-user-logout', LogoutPage);