import { PageElement, html, css } from 'Components';

class ValidationEmailPage extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        p {
          text-align: center;
        }

        m-icon {
          font-size: 3em;
        }

        .message {
          font-size: 1.9em;
        }

        .green {
          color:var(--sl-color-green-700);
        }
      `
    ]
  }
  
  render() {
    return html`
      <section-header micon="verified">Validation des contacts utilisateurs</section-header><br/>
      <br/>
      <p class="message">
        Votre adresse email a bien été validée.<br/><br/>
        <m-icon name="mark_email_read" class="green"></m-icon>
      </p>
      <p>
        <br/><br/><br/>
        Merci. Vous pouvez maintenant quitter cette page ou retourner à l'accueil.
      </p>
      <br/>
    `;
  }
}

customElements.define('page-validation-email', ValidationEmailPage);