import { PageElement, html, css } from 'Components';

import './tab_static.js';
import './tab_realtime.js';

class Page extends PageElement {
  static get styles() {
    return [
      super.styles,
      css``
    ];
  }

  firstUpdated() {
    super.firstUpdated();
    this.tab = {
      realtime:this.shadowRoot.querySelector('tab-apex-realtime'),
      static:this.shadowRoot.querySelector('tab-apex-static')
    }
  }

  showTab(ev) {
    if (ev.detail.name === 'realtime') {
      this.tab.realtime.show();
    } else {
      this.tab.realtime.hide();
    }
  }

  render() {

    return html`
      <section-header backroute="/public/tests" micon="developer_board">Tests UX</section-header>
      <h3>
        <div style="display:flex;align-items:center;">
          <div><b>&lt;apex-chart&gt;</b></div>
          <div style="font-weight:normal;padding-left:40px;font-size:0.8em;">Un wrapper on top of <a href="https://apexcharts.com/" target="apex">apexcharts.com</a>.</div>
        </div>
      </h3>

      <tab-group @sl-tab-show=${this.showTab}>
        <sl-tab slot="nav" panel="static">Statique</sl-tab>
        <sl-tab slot="nav" panel="realtime">Temps réel</sl-tab>

        <sl-tab-panel name="static" style="overflow:hidden;">
          <tab-apex-static></tab-apex-static>
        </sl-tab-panel>

        <sl-tab-panel name="realtime">
          <tab-apex-realtime debug showNative></tab-apex-realtime>
        </sl-tab-panel>
      </tab-group>

      <br/><br/><br/>
    `;
  }
}

customElements.define('page-tests-apexcharts', Page);
