import { PageElement, html, css } from 'Components';
import { Router } from 'Router';

import './modal_dialog.js';
import './modal_drawer.js';
import './reveal/index.js';
import './apex_chart/index.js';
import './section_header.js';
import './sl_tab_group.js';
import './tab_group.js';
import './i18n.js';
import './select_permissions.js';
import './pretty_json.js';
import './context_menu.js';
import './cherry_editor.js';

class TestUXPage extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        table {
          width:100%;
        }

        th {
          text-align:left;
        }

        tr {
          cursor:pointer;
        }

        th, td {
          padding:5px;
        }

        tbody tr:nth-child(odd) {
          background-color: var(--sl-color-neutral-200);
        }
      `
    ]
  }
  
  go(ev) {
    const tr = ev.target.closest('tr');
    Router.go('/public/tests/'+tr.getAttribute('href'));
  }

  render() {
    return html`
      <section-header backroute="/private" micon="developer_board">Tests UX</section-header><br/>
      <br/>
      <table>
        <thead>
          <th>Composants</th>
          <th>Notes</th>
        </thead>
        <tbody>
          <tr @click=${this.go} href="i18n"><td>i18n</td><td>Internationalisation</td></tr>
          <tr @click=${this.go} href="modal_dialog"><td>modal-dialog</td><td>Boîte de dialogue modale</td></tr>
          <tr @click=${this.go} href="modal_drawer"><td>modal-drawer</td><td>Fenêtre surgissante modale</td></tr>
          <tr @click=${this.go} href="reveal"><td>reveal</td><td>Wrapper reveal.js</td></tr>
          <tr @click=${this.go} href="apex_charts"><td>apex-chart</td><td>Wrapper apexcharts</td></tr>
          <tr @click=${this.go} href="section_header"><td>section-header</td><td>Section de pages</td></tr>
          <tr @click=${this.go} href="sl_tab_group"><td>sl-tab-group</td><td>Onglets natif Shoelace</td></tr>
          <tr @click=${this.go} href="tab_group"><td>tab-group</td><td>Onglets améliorés avec paramètres dans l'URL</td></tr>
          <tr @click=${this.go} href="select_permissions"><td>select-permissions</td><td>Menu à choix multiple pour les permissions</td></tr>
          <tr @click=${this.go} href="pretty_json"><td>pretty-json</td><td>Afficher du JSON de manière propre et pouvoir expand/collapse des niveaux</td></tr>
          <tr @click=${this.go} href="context_menu"><td>context-menu</td><td>Menu contextuel dynamique</td></tr>
          <tr @click=${this.go} href="cherry_editor"><td>cherry-editor</td><td>Editeur Cherry Markdown</td></tr>
        </tbody>
      </table>
      <br/>
    `;
  }
}

customElements.define('page-tests-ux', TestUXPage);