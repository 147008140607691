import { PageElement, html, css } from 'Components';

import 'Components/public/PrettyJson.js';

class PrettyJsonPage extends PageElement {

  static get properties() {
    return {
      object1: { type: Object },
      object2: { type: Object },
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        .flex {
          display:flex;
          align-items:top;
          gap:20px;
        }

        .flex > * {
          width:100%;
        }

        select-permissions {
          width:800px;
        }

        b {
          font-size:2em;
          height:40px;
        }

        pretty-json {
          display:block;
          border:1px solid #ddd;
        }
      `
    ]
  }

  constructor() {
    super();
    this.debug = false;
    this.object1 = {
      'name': 'John',
      'age': 30,
      'cars': {
        'car1': 'Ford',
        'car2': 'BMW',
        'car3': 'Fiat'
      },
      'years':[1990,1991,1992,1993,1994,1995,1996,1997,1998,1999],
      'boolean':true,
    };

    this.object2 = {
      id: 123,
      name: 'Sample Object',
      details: {
        category: 'Category A',
        description: 'This is a description of the object.',
        metadata: {
          createdAt: '2023-09-23',
          updatedAt: '2024-01-15',
          tags: ['tag1', 'tag2', 'tag3'],
          additionalInfo: {
            status: 'active',
          }
        }
      },
      isActive: true,
      rating: 4.5
    };
  }

  updated(changedProperties) {
    super.updated(changedProperties);
    if (changedProperties.has('object1')) {
      this.shadowRoot.querySelector('pretty-json').requestUpdate();
    }
  }

  firstUpdated() {
    super.firstUpdated();
    let i = 0;
    this.timerInterval = setInterval(async () => {
      this.object1.age += 1;
      this.object1 = { ...this.object1 };
      this.object2.id += 1;
      this.object2.details.metadata.updatedAt = new Date().toISOString();
      this.object2 = { ...this.object2 };
      //await this.requestUpdate();
      i+=1;
      if (i > 10) {
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }

  disconnectedCallback() {
    clearInterval(this.timerInterval);
    super.disconnectedCallback();
  }

  render() {
    return html`
      <section-header backroute="/public/tests" micon="developer_board">Tests UX</section-header><br/><br/>
      <div class="flex">
        <div>
          <section-header size="normal">Slot content (not updated automagically)</section-header>
          <pretty-json>${JSON.stringify(this.object1)}</pretty-json>
        </div>
        <div>
          <section-header size="normal">Property content (need to pass a copy of the object)</section-header>
          <pretty-json .jsonData=${ { ... this.object2 }}></pretty-json>
        </div>
      </div>
      <br/><br/><br/>
    `;
  }
}

customElements.define('page-tests-pretty-json', PrettyJsonPage);
