import { PageElement, html, css } from 'Components';

class CherryEditorPage extends PageElement {

  static get styles() {
    return [
      super.styles,
      css`
        pre {
          padding:20px;
          margin:10px;
          font-size:0.9em;
          border:1px solid var(--sl-color-neutral-300)
        }

        p {
          font-size:1.2em;
          margin-left:40px;
        }

        .flex {
          display:flex;
        }

        sl-tree {
          padding:10px;
          margin:10px;
          background-color:var(--sl-panel-background-color);
        }

        sl-tree-item::part(label) {
          font-size:0.9rem;
        }

        sl-tree-item::part(expand-button) {
          padding:3px;
        }
      `
    ]
  }

  static get properties() {
    return {
    };
  }

  constructor() {
    super();
  }

  firstUpdated() {
    super.firstUpdated();
  }

  render() {
    return html`
      <section-header backroute="/public/tests" micon="developer_board">Tests UX</section-header>
      <h3>
        <div style="display:flex;align-items:center;">
          <div><b>&lt;cherry-editor&gt;</b></div>
          <div style="font-weight:normal;padding-left:40px;font-size:0.8em;">Un wrapper on top of <a href="https://github.com/Tencent/cherry-markdown" target="cherry">cherry-markdown</a>.</div>
        </div>
      </h3>      
      <sl-tab-group>
        
        <sl-tab slot="nav" panel="tab1">Composant</sl-tab>
        <sl-tab slot="nav" panel="tab2">Status</sl-tab>
        <sl-tab-panel name="tab1">
          <cherry-editor></cherry-editor>
        </sl-tab-panel>

        <sl-tab-panel name="tab2">
          Cherry Editor est un éditeur markdown très puissant.<br/><br/>
          Ceci dit, son code est réalisé par des étudiants, le github est majoritairement en chinois, la documentation n'est pas à jour.<br/><br/>
          Il est en théorie étendable mais pour l'instant choux blanc.<br/><br/>
          Toute alternative est à considérer.
        </sl-tab-panel>

      </sl-tab-group>

      <br/><br/>
    `;
  }

}

customElements.define('page-tests-cherry-editor', CherryEditorPage);