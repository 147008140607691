import { PageElement, html, css } from 'Components';

class Page extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
      `
    ];
  }

  render() {
    return html`
      <section-header backroute="/public/tests" micon="developer_board">Tests UX</section-header>
      <h3>
        <div style="display:flex;align-items:center;">
          <div><b>&lt;modal-dialog&gt;</b></div>
          <div style="font-weight:normal;padding-left:40px;font-size:0.8em;">Refactor du composant shoelace <a href="https://shoelace.style/components/dialog" target="shoelace">sl-dialog</a>.</div>
        </div>
      </h3>
      <br/>
      <ul>
        <li>slots pour les boutons</li>
        <li>meilleur maîtrise de l'overlay (fondu progressif vers la suppression des couleurs via <i>backdrop-filter</i>)</li>
        <li>placement correct même si le composant actuel ou un des composants parents utilise position:relative ou position:fixed</li>
        <li>blocage mousewheel sur le composant parent uniquement (le modal peut contenu des elements scrollable)</li>
        <li>suppression fleckring de la page lié à sa taille (scrollbar + margin-right) lors de l'ouverture du modal</li>
      </ul>
      <br/>

      <sl-button @click=${this.openModal}>Ouvrir la fenêtre modale</sl-button>
      
      <modal-dialog label="modal title">
        Hello world !
        <sl-button slot="bt1" variant="text" @click=${this.closeModal}>slot bt1 - Close</sl-button>
        <sl-button slot="bt2" variant="primary" @click=${this.closeModal}>slot bt2 - OK</sl-button>
      </modal-dialog>
    `;
  }

  openModal() {
    this.shadowRoot.querySelector('modal-dialog').show();
  }

  closeModal() {
    this.shadowRoot.querySelector('modal-dialog').hide();
  }

}

customElements.define('page-tests-modal-dialog', Page);