import { PageElement, html, css } from 'Components';
import { Loaders } from 'Utils';


class TabApexRealtime extends PageElement {

  static get properties() {
    return {
      showNative: { type:Boolean },
      height: { type:String },
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        .graphs {
          width:100%;
          display:flex;
          align-items:center;
          justify-content:space-around;
          gap:20px;
        }

        .graphs > * {
          border:1px solid var(--sl-color-neutral-300);
          flex:1;
          overflow:hidden;
        }
      `
    ];
  }

  constructor() {
    super();
    this.debug = false;
    this.showNative = false;
    this.lastDate = 0;
    this.TICKINTERVAL = 86400;
    this.XAXISRANGE = 777600;
    this.height = '60vh';
    this.data1 = [];
    this.data2 = [];
    this.intervalDelay = 2000;

    this.options = {
      series: [
        { data: this.data1.slice() },
        { data: this.data2.slice() }
      ],
      chart: {
        height:'100%',
        width:'100%',
        type: 'line',
        stacked: false,
        animations: {
          enabled: true,
          easing: 'linear',
          dynamicAnimation: { speed: this.intervalDelay }
        },
        dropShadow: {
          enabled: true,
          opacity: 0.3,
          blur: 5,
          left: -7,
          top: 22
        },
        toolbar: { show: false },
        zoom: { enabled: false }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          gradientToColors: [ '#FDD835'],
          shadeIntensity: 1,
          type: 'horizontal',
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100]
        },
      },
      title: { text: 'VanilliaJS' },
      dataLabels: { enabled: false },
      stroke: { curve: 'smooth' },
      markers: { size: 0 },
      xaxis: { type: 'datetime', range: this.XAXISRANGE },
      //yaxis: { max: 200 },
      legend: { show: false },
    };

    this.onThemeChange = this.onThemeChange.bind(this);
  }


  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('theme-change', this.onThemeChange);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('theme-change', this.onThemeChange);
    clearInterval(this.loopInterval);
  }

  async visibleCallback() {
    this._log.warn('visibleCallback');
  }

  hiddenCallback() {
    super.hiddenCallback();
    clearInterval(this.loopInterval);
  }

  onThemeChange(e) {
    this._log.debug(this.id, 'onThemeChange', e.detail);
    // @TODO: implement
    //const value = e.detail.isDark ? 'dark' : 'light';
    //this.shadowRoot.querySelector('sl-select[name="fillGradientShade"]').value = value;
  }

  /*
  async firstUpdated() {
    try {
      await loadApexCharts();
      this._log.debug('ApexCharts library loaded.');
    } catch (error) {
      this._log.error('Failed to load ApexCharts library', error);
    }
  }
  */

  async loop() {
    this.loopRealtime = this.loopRealtime.bind(this);
    this.intervalRuns = 0;
    this.loopRealtime();
    this.loopInterval = setInterval(this.loopRealtime, this.intervalDelay);
  }

  loopRealtime() {
    this.intervalRuns++;
    this.getNewSeries(this.lastDate, { min: 10, max: 90 });
    this.chartRealtimeNative?.updateSeries([{ data:this.data1 }, { data:this.data2 }]);
    this.chartRealtimeComponent?.updateSeries([{ data:this.data1 }, { data:this.data2 }]);
    if (this.intervalRuns === 60 * 5) { clearInterval(this.loopInterval) }
  }

  getDayWiseTimeSeries(baseval, count, yrange) {
    let i = 0;
    while (i < count) {
      let x = baseval;
      let y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
      this.data1.push({ x, y });

      x = baseval;
      y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
      this.data2.push({ x, y });

      this.lastDate = baseval,
      baseval += this.TICKINTERVAL;
      i++;
    }
  }

  getNewSeries(baseval, yrange) {
    const newDate = baseval + this.TICKINTERVAL;
    this.lastDate = newDate;

    for(let i = 0; i< this.data1.length - 10; i++) {
      // IMPORTANT
      // we reset the x and y of the data which is out of drawing area
      // to prevent memory leaks
      this.data1[i].x = this.data2[i].x = newDate - this.XAXISRANGE - this.TICKINTERVAL;
      this.data1[i].y = this.data2[i].y = 0;
    }

    this.data1.push({ x: newDate, y: Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min })
    this.data2.push({ x: newDate, y: Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min })
  }

  async renderRealtimeNative() {
    const container = this.shadowRoot.querySelector('#apex-live-native');    
    this.chartRealtimeNative = new ApexCharts(container, this.options);
    this.chartRealtimeNative.render();
  }

  async renderRealtimeComponent() {
    this.chartRealtimeComponent = this.shadowRoot.querySelector('#apex-live');
    const options = { ...this.options };
    if (this.showNative) {
      options.title = { text: 'LitElement WebComponent' };
    } else {
      options.title = { text: '' };
    }
    this.chartRealtimeComponent.options = options;
    await this.chartRealtimeComponent.renderChart(options);
  }

  async show() {
    this._log.debug('show');
    await Loaders.loadApexCharts();
    this.getDayWiseTimeSeries(new Date('11 Feb 2017 GMT').getTime(), 10, { min: 10, max: 90 });
    this.getNewSeries(this.lastDate, { min: 10, max: 90 });
    this.options.series = [{ data:this.data1 }, { data:this.data2 }];
      
    if (this.showNative) this.renderRealtimeNative();
    this.renderRealtimeComponent();
    this.loop();
  }

  async hide() {
    this._log.debug('hide');
    clearInterval(this.loopInterval);
  }
  
  render() {
    return html`
      <div class="graphs">
        ${this.showNative 
          ? html`<div id="apex-live-native" style="height:${this.height};"></div>`
          : ''
        }
        <apex-chart id="apex-live" style="height:${this.height};"></apex-chart>
      </div>
    `;
  }
}

customElements.define('tab-apex-realtime', TabApexRealtime);
